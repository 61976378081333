import React from 'react'
import styled from 'styled-components'
import Path from 'svg-path-generator'
import intersection from 'rectangle-overlap'
import produce from 'immer'
import { saveAs } from 'file-saver'
import { v4 } from 'uuid'
import { format } from 'date-fns'
import { Button } from '@chakra-ui/button'
import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/slider'
import useLocalStorageState from 'use-local-storage-state'
import { Input } from '@chakra-ui/input'

import svg_esch from './E22_LOGO_EC_CULTURE_RGB_V.png'
import svg_101 from './101-logo.svg'

const randomInRange = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const colorProfiles = {
  rgb: {
    red: '#e52313',
    blue: '#00acab',
  },
  cmyk: {
    red: 'cmyk(0,85,92,10)',
    blue: 'cmyk(100,0,1,33)',
  },
}

function App() {
  const [isLoggedIn, setIsloggedIn] = useLocalStorageState('logged_in', false)

  const groupRef = React.useRef(null)
  const ref = React.useRef(null)

  const [selectedColorProfile, setSelectedColorProfile] = React.useState('rgb')

  const colors = React.useMemo(
    () => colorProfiles[selectedColorProfile],
    [selectedColorProfile]
  )

  React.useEffect(() => console.log(colors), [colors])

  const [setter, setSetter] = React.useState(true)
  const [blocks, setBlocks] = React.useState([])

  const [dragged, setDragged] = React.useState(null)
  const [dragDirty, setDragDirty] = React.useState(false)
  const [isMouseOver, setIsMouseOver] = React.useState(false)

  // Configs
  const [canvasConfig, setCanvasConfig] = React.useState({
    width: 1000,
    height: 400,
  })
  const [blocksConfig, setBlocksConfig] = React.useState({
    minWidth: 150,
    maxWidth: 300,
    minHeight: 80,
    maxHeight: 80,
  })

  // States
  const [blocksCount, setBlocksCount] = React.useState(5)

  const [colorDistribution, setColorDistribution] = React.useState({
    red: 5,
    blue: 5,
  })

  const [rounding, setRounding] = React.useState(21)

  const round = n => Math.round(n / rounding) * rounding

  const updateColorDistribution = blocks => {
    const nextBlocks = blocks.map((block, i, all) => {
      const redPercentage =
        (colorDistribution.red /
          (colorDistribution.red + colorDistribution.blue)) *
        100

      const iPercentage = (i / all.length) * 100

      const fill = iPercentage >= redPercentage ? colors.blue : colors.red

      return {
        ...block,
        fill,
        profile: iPercentage >= redPercentage ? 'blue' : 'red',
      }
    })

    return nextBlocks
  }

  const updateAll = () => {
    // rebuild blocks on any property change
    const nextBlocks = Array.from({ length: blocksCount }).map(() => {
      const width = randomInRange(blocksConfig.minWidth, blocksConfig.maxWidth)
      const height = randomInRange(
        blocksConfig.minHeight,
        blocksConfig.maxHeight
      )

      const x = randomInRange(0, canvasConfig.width - width)
      const y = randomInRange(0, canvasConfig.height - height)

      return {
        width,
        height,
        x,
        y,
      }
    })

    setBlocks(updateColorDistribution(nextBlocks))
  }

  React.useEffect(() => {
    setBlocks(updateColorDistribution(blocks))
  }, [colorDistribution])

  React.useEffect(() => {
    updateAll()
  }, [])

  React.useEffect(() => {
    updateAll()
  }, [setter, blocksCount, blocksConfig])

  React.useEffect(() => {}, [canvasConfig])

  const handleDownload = () => {
    const clone = ref.current.cloneNode(true)

    clone.querySelector('.bg').remove()
    const lines = [...clone.querySelectorAll('line')]

    console.log(lines)

    lines.forEach(el => {
      el.remove()
    })

    const svg_data_uri = 'data:image/svg+xml;base64,' + btoa(clone.outerHTML)

    saveAs(svg_data_uri, `${format(new Date(), 'dd-MM-yyyy kk:mm-T')}`)
  }

  if (!isLoggedIn)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Input
          size='lg'
          style={{ width: '150px' }}
          placeholder='____'
          letterSpacing='5px'
          textAlign='center'
          fontWeight='600'
          maxLength='4'
          onChange={e => {
            if (e.target.value === '1010') setIsloggedIn(true)
          }}
        />
      </div>
    )

  return (
    <Container>
      <div className='side'>
        <Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <img src={svg_101} />
          <img src={svg_esch} />
        </Header>
        <Group>
          <GroupTitle>Global</GroupTitle>
          <Label>
            <span>Block Count ({blocksCount})</span>

            <Slider
              aria-label='slider-ex-1'
              min={1}
              max={30}
              value={blocksCount}
              onChange={e => setBlocksCount(e)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
          <Label>
            <span>Grid Snap</span>

            <Slider
              aria-label='slider-ex-1'
              min={1}
              max={100}
              step={10}
              value={rounding}
              onChange={e => setRounding(e === 0 ? 1 : e)}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
          <Description>
            Rounding creates a grid to which block can snap. (
            {rounding === 0 || rounding === 1 ? 'OFF' : `near ${rounding}`})
          </Description>
        </Group>
        <Group>
          <GroupTitle>AREA SIZE</GroupTitle>
          <Label>
            <span> Width ({canvasConfig.width}px)</span>

            <Slider
              aria-label='slider-ex-1'
              min={0}
              max={2000}
              step={50}
              value={round(canvasConfig.width)}
              onChange={e => {
                if (canvasConfig.width !== e)
                  setCanvasConfig({ ...canvasConfig, width: e })
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
          <Label>
            <span> Height ({canvasConfig.height}px)</span>

            <Slider
              aria-label='slider-ex-1'
              min={0}
              step={50}
              max={2000}
              value={round(canvasConfig.height)}
              onChange={e => {
                if (canvasConfig.height !== e)
                  setCanvasConfig({ ...canvasConfig, height: e })
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
        </Group>
        <Group>
          <GroupTitle>Color Distribution</GroupTitle>
          <Label>
            <Slider
              aria-label='slider-ex-1'
              min={0}
              max={10}
              step={1}
              value={colorDistribution.red}
              onChange={e => {
                setColorDistribution({
                  red: e,
                  blue: 10 - e,
                })
              }}
            >
              <SliderTrack bg={colors.blue}>
                <SliderFilledTrack bg={colors.red} />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
        </Group>
        <Group>
          <GroupTitle>BLOCKS CONFIG</GroupTitle>
          <Label>
            <span>Min Width ({blocksConfig.minWidth}px)</span>

            <Slider
              aria-label='slider-ex-1'
              min={0}
              max={500}
              step={20}
              value={blocksConfig.minWidth}
              onChange={e =>
                setBlocksConfig({
                  ...blocksConfig,
                  minWidth: e,
                  maxWidth: Math.max(blocksConfig.maxWidth, e),
                })
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
          <Label>
            <span>Max Width ({blocksConfig.maxWidth}px)</span>

            <Slider
              aria-label='slider-ex-1'
              min={0}
              max={500}
              step={20}
              value={blocksConfig.maxWidth}
              onChange={e =>
                setBlocksConfig({
                  ...blocksConfig,
                  maxWidth: e,
                  minWidth: Math.min(blocksConfig.minWidth, e),
                })
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>

          <Label>
            <span>Min Height ({blocksConfig.minHeight}px)</span>

            <Slider
              aria-label='slider-ex-1'
              min={0}
              max={500}
              step={20}
              value={blocksConfig.minHeight}
              onChange={e =>
                setBlocksConfig({
                  ...blocksConfig,
                  minHeight: e,
                  maxHeight: Math.max(blocksConfig.maxHeight, e),
                })
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
          <Label>
            <span>Max Height ({blocksConfig.maxHeight}px)</span>

            <Slider
              aria-label='slider-ex-1'
              min={0}
              max={500}
              step={20}
              value={blocksConfig.maxHeight}
              onChange={e =>
                setBlocksConfig({
                  ...blocksConfig,
                  maxHeight: e,
                  minHeight: Math.min(blocksConfig.minHeight, e),
                })
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={4} />
            </Slider>
          </Label>
        </Group>
        <Button
          size='sm'
          variant='solid'
          colorScheme='blue'
          style={{ marginTop: '30px' }}
          onClick={() => setSetter(!setter)}
        >
          🎨 Repaint
        </Button>

        <Button
          variant='outline'
          style={{ marginTop: '10px' }}
          onClick={handleDownload}
          size='sm'
        >
          Export as SVG
        </Button>
      </div>
      <div className='main'>
        <svg
          ref={ref}
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          onMouseMove={e => {
            if (typeof dragged === 'number') {
              setDragDirty(true)
              setBlocks(
                produce(blocks, draft => {
                  draft[dragged].x += e.movementX
                  draft[dragged].y += e.movementY
                })
              )
            }
          }}
          onMouseUp={() => {
            setDragged(null)
            setDragDirty(false)
          }}
          width={canvasConfig.width}
          height={canvasConfig.height}
          viewBox={`0 0 ${canvasConfig.width} ${canvasConfig.height}`}
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            class='bg'
            stroke='#E0E0E0'
            width={canvasConfig.width}
            height={canvasConfig.height}
          ></rect>

          {Array.from({
            length: rounding === 1 ? 0 : canvasConfig.height / rounding - 1,
          }).map((_, i) => {
            return (
              <line
                class='line'
                x1='0'
                y1={(i + 1) * rounding}
                x2={canvasConfig.width}
                y2={(i + 1) * rounding}
                stroke='#E0E0E0'
              />
            )
          })}

          {Array.from({
            length: rounding === 1 ? 0 : canvasConfig.width / rounding - 1,
          }).map((_, i) => {
            return (
              <line
                x1={(i + 1) * rounding}
                y1={canvasConfig.height}
                x2={(i + 1) * rounding}
                y2='0'
                stroke='#E0E0E0'
              />
            )
          })}

          {blocks
            .map(({ width, height, x, y, ...rest }) => ({
              ...rest,
              width: round(width),
              height: round(height),
              x: round(x),
              y: round(y),
            }))
            .map(({ width, height, x, y, fill, profile }, i, all) => {
              const previous = all.slice(0, i)

              const collisions = previous
                .map(prev => {
                  const overlap = intersection(prev, { width, height, x, y })

                  return overlap
                })
                .filter(v => v !== null)

              return (
                <>
                  <rect
                    style={{ cursor: 'grab' }}
                    width={width}
                    height={height}
                    x={x}
                    y={y}
                    fill={colors[profile]}
                    onMouseDown={() => {
                      setDragged(i)
                    }}
                    onClick={e => {
                      setBlocks(
                        produce(blocks, draft => {
                          draft[i].profile =
                            draft[i].profile === 'red' ? 'blue' : 'red'
                        })
                      )
                    }}
                  ></rect>
                  {collisions.map(collision => {
                    return (
                      <rect
                        style={{ pointerEvents: 'none' }}
                        width={collision.width}
                        height={collision.height}
                        x={collision.x}
                        y={collision.y}
                        fill={'#452a30'}
                      ></rect>
                    )
                  })}
                </>
              )
            })}
        </svg>
      </div>
    </Container>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;

  img {
    height: 50px;
  }

  & img:first-of-type {
    height: 60px;
  }
`

const Description = styled.div`
  opacity: 0.5;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  font-style: italic;
`

const Group = styled.div`
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;

  input,
  button {
    margin-bottom: 15px;
  }
`

const GroupTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 8px;
  opacity: 0.4;
  text-transform: uppercase;
  /* color: #00acab; */
  margin-top: 20px;
`

const Label = styled.div`
  display: flex;
  margin: 10px 0px 0px 0px;

  span {
    font-size: 12px;
    font-weight: 600;
    min-width: 140px;
  }

  input {
    flex: 1;
  }
`

const Container = styled.div`
  display: flex;
  align-items: stretch;

  width: 100%;
  height: 100vh;

  .side {
    flex: 0 400px;
    min-width: 400px;

    border-right: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;

    padding: 0px 20px;

    overflow-y: auto;
    padding: 20px 25px 50px 25px;
  }

  .main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }

  button {
    font-weight: 500;
  }
`

export default App
